import classNames from 'classnames'
import { useSession } from 'next-auth/react'
import { Translate } from 'next-translate'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { RiCheckboxBlankCircleFill } from 'react-icons/ri'

import { at, getLocaleFromString } from '@elendi/util-helpers'
import { LOCALES_ENUM } from '@elendi/util-shared-constants'
import { range } from '@elendi/util-tspy'
import { AllTeamMember, PageType } from '@elendi/util-types'

import { PulsingPill } from '@elendi/ui-foundations'
import { SkeletonLoader } from '@elendi/ui-loaders'

import { maintainImpersonate } from '@elendi/feature-authentication/utils'
import { UserSurveyModulesJoined } from '@elendi/feature-survey-modules/shared'
import { useUserSurveyModules } from '@elendi/feature-survey-modules/ui'
import { useUserInfos } from '@elendi/feature-user-profile/hooks'

import { CrumbBase } from '../../Breadcrumbs'
import { NavbarLink, getEmployeeLinks, getManagerLinks } from './Links'

type NavigationProps = {
  pageType: PageType
  breadcrumbs: Array<CrumbBase>
}

export const Navigation = ({ pageType, breadcrumbs }: NavigationProps) => (
  <nav className='h-full w-full max-w-7xl grid auto-cols-fr grid-flow-col gap-1 px-4'>
    <NavigationLinks pageType={pageType} breadcrumbs={breadcrumbs} />
  </nav>
)

export const NavigationLinks = ({ pageType, breadcrumbs }: NavigationProps) => {
  const { t, lang } = useTranslation()
  const { data: session } = useSession()
  const { userInfos, isLoading, isError } = useUserInfos()
  const { userSurveyModules, isLoading: loadingSurveyModules } = useUserSurveyModules()

  if (isLoading || loadingSurveyModules)
    return (
      <>
        {range(4).map((idx) => (
          <NavigationLinkLoader key={idx} />
        ))}
      </>
    )
  if (isError || !session) return null

  const links = getLinks({
    t,
    pageType,
    userInfos,
    userSurveyModules,
    locale: getLocaleFromString(lang),
  }).sort((a, b) => a.level - b.level)

  return (
    <>
      {links
        .filter((link) => link.show)
        .map(({ href, label, icon, highlight }, idx) => (
          <NavigationLink
            key={idx}
            href={href}
            label={label}
            icon={icon}
            breadcrumbs={breadcrumbs}
            highlight={highlight}
          />
        ))}
    </>
  )
}

const NavigationLink = ({
  href,
  label,
  icon,
  breadcrumbs,
  highlight,
}: Pick<NavbarLink, 'href' | 'icon' | 'label'> & {
  breadcrumbs: NavigationProps['breadcrumbs']
  highlight?: boolean
}) => {
  const { query, asPath } = useRouter()
  const isActive =
    href === at(asPath.split('?'), 0) ||
    !!breadcrumbs.find((breadcrumb) => href === breadcrumb.href && !breadcrumb.inactiveNav)

  return (
    <Link
      href={{ pathname: href, query: maintainImpersonate({ query }) }}
      key={href}
      className='group relative h-full flex flex-col justify-center items-center gap-1 text-center text-slate-600 dark:text-slate-200'
    >
      <span className={`flex text-xl ${!isActive && 'opacity-70'}`}>{icon(isActive)}</span>

      <p className={`text-xs whitespace-nowrap ${isActive && 'font-semibold'} hidden sm:block`}>
        {label}
      </p>

      <span
        className={classNames(
          'absolute h-1 w-full rounded-full top-1 lg:top-auto lg:bottom-0.5',
          isActive
            ? 'bg-slate-600 dark:bg-slate-200'
            : 'bg-transparent group-hover:bg-slate-600/20 dark:group-hover:bg-slate-200/20',
        )}
      />

      {highlight && !isActive && <PulsingPill className='absolute top-2 left-1/2 ml-2 w-2 h-2' />}
    </Link>
  )
}

const NavigationLinkLoader = () => (
  <div className='group animate-pulse relative h-full flex flex-col justify-center items-center gap-1 text-center text-slate-600 dark:text-slate-200'>
    <span className={`flex text-xl text-slate-300`}>
      <RiCheckboxBlankCircleFill />
    </span>

    <SkeletonLoader height={'small'} />
  </div>
)

type getLinksArgs = {
  t: Translate
  pageType: PageType
  userInfos?: AllTeamMember | null
  userSurveyModules?: UserSurveyModulesJoined | null
  locale: LOCALES_ENUM
}

const getLinks = ({ t, pageType, userInfos, userSurveyModules, locale }: getLinksArgs) => {
  const {
    canAccessManagerPage,
    canAccessStructuralEngagementReport,
    canAccessNovadeEngagement,
    canAccessMyOrganizationAsHr,
    canAccessAiCompanion,
    canAccessCapabilities,
    canAccessObjectives,
    canAccessDevelopmentPlans,
    canAccessPulse,
    canAccessKudos,
    canAccessCapabilitiesManager,
    canAccessObjectivesManager,
    canAccessDevelopmentPlansManager,
    canAccessPulseManager,
    canAccessLearningPrograms,
  } = userInfos ?? {}

  switch (pageType) {
    case PageType.ENTERPRISE_USER:
      return getEmployeeLinks({
        t,
        canAccessCapabilities,
        canAccessLearningPrograms,
        canAccessObjectives,
        canAccessDevelopmentPlans,
        canAccessPulse,
        canAccessKudos,
        canAccessAiCompanion,
        userSurveyModules,
        locale,
      })
    case PageType.ENTERPRISE_MANAGER:
      return getManagerLinks({
        t,
        canAccessCapabilitiesManager,
        canAccessObjectivesManager,
        canAccessDevelopmentPlansManager,
        canAccessPulseManager,
        canAccessStructuralEngagementReport,
        canAccessNovadeEngagement,
        canAccessMyOrganizationAsHr,
        canAccessManagerPage,
      })
    default:
      return []
  }
}
