import { Translate } from 'next-translate'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'

import { ROUTE } from '@elendi/util-endpoints'
import { type AllTeamMember, PageType } from '@elendi/util-types'

import { maintainImpersonate } from '@elendi/feature-authentication/utils'
import { useUserInfos } from '@elendi/feature-user-profile/hooks'

import { getEmployeeLinks, getLowestLevelAccessibleLink, getManagerLinks } from './Links'
import { ViewSwitch } from './ViewSwitch'

type ManagerViewSwitchProps = {
  pageType: PageType
}
export const ManagerViewSwitch = ({ pageType }: ManagerViewSwitchProps) => {
  const { t } = useTranslation()
  const router = useRouter()
  const { userInfos, isLoading: userInfosIsLoading, isError: userInfosIsError } = useUserInfos()

  const { isAdmin: isAdminBool, canAccessManagerPage } = userInfos ?? {}
  const userIsAtLeastManager = isAdminBool || canAccessManagerPage

  if (userInfosIsLoading || userInfosIsError || !userIsAtLeastManager) return null

  const pathname =
    getSwitchLinkHref({
      t,
      pageType,
      userInfos,
    }) ?? ROUTE.user.home

  return (
    <ViewSwitch
      href={{ pathname, query: maintainImpersonate({ query: router.query }) }}
      text={t('common:header.manager-view')}
      checked={pageType === PageType.ENTERPRISE_MANAGER}
    />
  )
}

const getSwitchLinkHref = ({
  t,
  pageType,
  userInfos,
}: {
  t: Translate
  pageType: PageType
  userInfos?: AllTeamMember | null
}): string | null => {
  const {
    canAccessManagerPage,
    canAccessStructuralEngagementReport,
    canAccessNovadeEngagement,
    canAccessMyOrganizationAsHr,
    canAccessAiCompanion,
    canAccessCapabilities,
    canAccessObjectives,
    canAccessDevelopmentPlans,
    canAccessPulse,
    canAccessKudos,
    canAccessCapabilitiesManager,
    canAccessObjectivesManager,
    canAccessDevelopmentPlansManager,
    canAccessPulseManager,
    canAccessLearningPrograms,
  } = userInfos ?? {}

  switch (pageType) {
    case PageType.ENTERPRISE_USER:
      return getLowestLevelAccessibleLink(
        getManagerLinks({
          t,
          canAccessCapabilitiesManager,
          canAccessObjectivesManager,
          canAccessDevelopmentPlansManager,
          canAccessPulseManager,
          canAccessStructuralEngagementReport,
          canAccessNovadeEngagement,
          canAccessMyOrganizationAsHr,
          canAccessManagerPage,
        }),
      )
    case PageType.ENTERPRISE_MANAGER:
      return getLowestLevelAccessibleLink(
        getEmployeeLinks({
          t,
          canAccessCapabilities,
          canAccessObjectives,
          canAccessDevelopmentPlans,
          canAccessPulse,
          canAccessKudos,
          canAccessAiCompanion,
          canAccessLearningPrograms,
        }),
      )
  }
}
